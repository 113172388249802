import React from 'react'

import {LinkBox, HStack, LinkOverlay, Icon, Text} from '@chakra-ui/react'
import {BiRightArrowAlt} from 'react-icons/bi'
import {TFunction} from 'react-i18next'

type LinkBoxToSignupOtherVouchersProps = {
  campaignListingUrl: string
  t: TFunction<'campaignErrors', 'inactive' | 'expired'>
}

const LinkBoxToSignupOtherVouchers = ({
  campaignListingUrl,
  t,
}: LinkBoxToSignupOtherVouchersProps): JSX.Element => {
  return (
    <LinkBox
      background='primary.500'
      border='1px'
      borderColor='primary.500'
      borderRadius='4px'
    >
      <HStack
        align='center'
        justify='space-between'
        color='white'
        paddingX='32px'
        paddingY='16px'
        spacing='0'
      >
        <LinkOverlay
          href={campaignListingUrl}
          id='see-other-available-vouchers-link'
          isExternal
        >
          <Text textStyle='subhead1'>{t('other_vouchers')}</Text>
        </LinkOverlay>
        <Icon as={BiRightArrowAlt} boxSize='24px' />
      </HStack>
    </LinkBox>
  )
}

export default LinkBoxToSignupOtherVouchers
