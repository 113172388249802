import {VStack, Text, Box, useBreakpointValue} from '@chakra-ui/react'
import {getTextColorForColorScheme} from 'helpers/vouchers'
import React from 'react'
import BackButton from 'pages/GroupPage/components/BackButton'
import {useTranslation} from 'react-i18next'
import {VoucherColorScheme} from 'types/voucher'
import {useIsShortDeviceInitial} from 'helpers/hooks'
import GeneralVouchersHeaderInfo from 'pages/GroupPage/components/GeneralVouchersHeaderInfo'

type RedemptionPageHeaderProps = {
  colorScheme: VoucherColorScheme
  onBackClick: () => void
  header?: string
  isGeneralVouchersCampaign: boolean
}

const RedemptionPageHeader = ({
  colorScheme,
  onBackClick,
  header,
  isGeneralVouchersCampaign,
}: RedemptionPageHeaderProps) => {
  const {t} = useTranslation('redemption')
  const isShortDevice = useIsShortDeviceInitial()

  const isSmallerThanXs = useBreakpointValue({
    base: true,
    xs: false,
  })

  return (
    <VStack
      align='start'
      width='100%'
      maxWidth='512px'
      color={getTextColorForColorScheme(colorScheme)}
      paddingX='16px'
      spacing={0}
    >
      <BackButton
        activeHoverColor={
          isGeneralVouchersCampaign ? '#C9CADA' : `${colorScheme}.600`
        }
        color={isGeneralVouchersCampaign ? 'neutral.900' : `currentColor`}
        id='redemption-page-back-button'
        onBackClick={onBackClick}
        text={t('back')}
      />
      {isGeneralVouchersCampaign ? (
        <Box
          position='relative'
          zIndex={-9999}
          alignSelf='stretch'
          width='100%'
          maxWidth='512px'
          height={isSmallerThanXs ? '52px' : '76px'}
          paddingX='8px'
        >
          <GeneralVouchersHeaderInfo />
        </Box>
      ) : (
        <Text
          textStyle={isShortDevice ? 'h4' : 'h3'}
          paddingTop='12px'
          paddingLeft='8px'
        >
          {header}
        </Text>
      )}
    </VStack>
  )
}

export default React.memo(RedemptionPageHeader)
