import cdcVouchersLogo from 'images/cdc-vouchers.svg'
import cdcSupermarketVouchersLogo from 'images/cdc-supermarket-vouchers.svg'

export type VoucherTypeConfigObject = {
  id: string
  src: string
  text: string
}

type VoucherTypeConfig = {
  [key: string]: VoucherTypeConfigObject
}

export const VOUCHER_TYPE_CONFIG: VoucherTypeConfig = {
  heartland: {
    id: 'cdc-heartland-vouchers-button',
    src: cdcVouchersLogo,
    text: 'CDC Vouchers',
  },
  supermarket: {
    id: 'cdc-supermarket-vouchers-button',
    src: cdcSupermarketVouchersLogo,
    text: 'CDC Supermarket Vouchers',
  },
}
