import {useState, useCallback} from 'react'
import {useQuery} from 'react-query'
import _ from 'lodash'

import {fetchGroupById} from 'services/RedeemApi'

import {is404Error} from 'helpers/utils'
import {GroupedVoucher, Voucher} from 'types/voucher'
import {Campaign} from 'types/campaign'

const RETRIES_MAX = 3

const useGroupWithRefetchInterval = (
  groupId: string,
  refetchInterval: number,
): {
  voucherGroup: Voucher[]
  groupData: GroupedVoucher
  campaign: Campaign
  fetchGroupByIdStatus: 'idle' | 'error' | 'loading' | 'success'
  isFetchingGroupById: boolean
  fetchGroupByIdError: unknown
} => {
  const [isRefetchEnabled, setIsRefetchEnabled] = useState(true)
  const disableRefetch = useCallback(() => setIsRefetchEnabled(false), [])
  const {data: response, status, isFetching, error} = useQuery(
    ['groups', groupId],
    () => fetchGroupById(groupId),
    {
      // Retry logic on failure
      retry: (failureCount, responseError) => {
        // Will retry when not 404
        // 404 means voucher not found
        return !is404Error(responseError) && failureCount < RETRIES_MAX
      },
      // This means that the hook does not automatically run
      refetchInterval: isRefetchEnabled ? refetchInterval : false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: isRefetchEnabled,
      onError: disableRefetch,
    },
  )

  const voucherGroup = _.get(response, 'data.vouchers', [])
  const groupData = _.get(response, 'data')
  const campaign = _.get(response, 'campaign')

  return {
    voucherGroup,
    groupData,
    campaign,
    fetchGroupByIdStatus: status,
    isFetchingGroupById: isFetching,
    fetchGroupByIdError: error,
  }
}

export default useGroupWithRefetchInterval
