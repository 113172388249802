import {useEffect} from 'react'

type RedirectProps = {
  to: string
}

const Redirect = ({to}: RedirectProps) => {
  useEffect(() => {
    window.location.href = to
  }, [])
  return <> </>
}

export default Redirect
