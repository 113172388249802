import {createBreakpoints} from '@chakra-ui/theme-tools'

export const breakpoints = createBreakpoints({
  xs: '320px',
  sm: '360px',
  md: '400px',
  lg: '760px',
  xl: '960px',
  '2xl': '1536px',
})
