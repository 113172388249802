import _ from 'lodash'

function getAppEnv() {
  return process.env.REACT_APP_ENV
}
// TODO: Refactor to define URLs in env vars rather than hardcoding them

// Define the api URLs here
const productionBaseUrl = 'https://api-cdc.redeem.gov.sg'
const stagingBaseUrl = 'https://api-cdc-staging.redeem.gov.sg'
const localBaseUrl = 'http://localhost:10000'
const vaptBaseUrl = 'https://api-vapt.redeem.gov.sg'

// Define the campaign listing URLs here
const productionCampaignListingUrl = 'https://signup.redeem.gov.sg/'
const stagingCampaignListingUrl = 'https://signup-staging.redeem.gov.sg/'
const localCampaignListingUrl = 'https://signup.redeem.gov.sg/'
const vaptCampaignListingUrl = 'https://signup-vapt.redeem.gov.sg/'

export function getVoucherBaseUrlForEnv(env: string | undefined) {
  if (env === 'production') {
    return productionBaseUrl
  }
  if (env === 'staging') {
    return stagingBaseUrl
  }
  if (env === 'vapt') {
    return vaptBaseUrl
  }
  return localBaseUrl
}

export function getVoucherBaseUrl() {
  return getVoucherBaseUrlForEnv(getAppEnv())
}

export function getCampaignListingUrl() {
  const appEnv = getAppEnv()
  if (appEnv === 'production') {
    return productionCampaignListingUrl
  }
  if (appEnv === 'staging') {
    return stagingCampaignListingUrl
  }
  if (appEnv === 'vapt') {
    return vaptCampaignListingUrl
  }
  return localCampaignListingUrl
}

export function getLaunchDarklySdkClientId() {
  return process.env.REACT_APP_LAUNCHDARKLY_SDK_CLIENT ?? ''
}

export function getIsLaunchDarklySdkClientAvailable() {
  const launchDarklySdkClient = getLaunchDarklySdkClientId()
  return !_.isEmpty(launchDarklySdkClient) && launchDarklySdkClient !== 'null'
}

export const REDEEM_CONSTANTS = {
  landingPage: 'https://redeem.gov.sg',
  supportEmail: 'feedback@redeem.gov.sg',
  termsOfUse: 'https://redeem.gov.sg/terms-of-use.html',
  privacy: 'https://redeem.gov.sg/privacy.html',
  reportVulnerability: 'https://www.tech.gov.sg/report_vulnerability',
  feedbackPage: 'https://go.gov.sg/redeemsgfeedback',
}
