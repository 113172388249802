import React from 'react'
import leftHand from 'images/left-hand.svg'
import rightHand from 'images/right-hand.svg'
import redeemsgRewards from 'images/redeemsg-rewards.svg'

import {HStack, VStack, Text, Image, useBreakpointValue} from '@chakra-ui/react'

type GeneralVouchersHeaderInfoProps = {
  formattedCampaignExpiresAt?: string
}

function GeneralVouchersHeaderInfo({
  formattedCampaignExpiresAt,
}: GeneralVouchersHeaderInfoProps) {
  const isSmallerThanXs = useBreakpointValue({
    base: true,
    xs: false,
  })

  return (
    <HStack
      position='relative'
      left='-24px'
      alignItems='center'
      justifyContent='space-between'
      width='calc(100% + 48px)'
      paddingTop={formattedCampaignExpiresAt ? '16px' : '0px'}
      id='general-vouchers-header-info-container'
      spacing='0'
    >
      <Image
        width={isSmallerThanXs ? '80px' : '101px'}
        height={isSmallerThanXs ? '70px' : '88px'}
        alt='Left hand'
        src={leftHand}
      />
      <VStack
        position='absolute'
        top='50%'
        left='50%'
        paddingTop={formattedCampaignExpiresAt ? '12px' : '0px'}
        textColor='neutral.900'
        transform='translate(-50%, -50%)'
        spacing='12px'
      >
        <Image
          alt='RedeemSG Rewards'
          paddingBottom={formattedCampaignExpiresAt ? '0px' : '16px'}
          src={redeemsgRewards}
        />
        {formattedCampaignExpiresAt ? (
          <Text textStyle='caption2' textAlign='center'>
            {formattedCampaignExpiresAt}
          </Text>
        ) : null}
      </VStack>
      <Image
        width={isSmallerThanXs ? '80px' : '105px'}
        height={isSmallerThanXs ? '95px' : '124px'}
        alt='Right hand'
        src={rightHand}
      />
    </HStack>
  )
}

export default GeneralVouchersHeaderInfo
