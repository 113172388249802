// This component is used to show expired vouchers from NEA Before 2024. This is meant to be just a simple static page
import {HStack, VStack, Image, Box, Text, Button} from '@chakra-ui/react'

import LanguageSelector from '../LanguageSelector'
import OldCFHPLogo from 'images/oldCFHPLogo.jpg'
import MobileWarnPng from 'images/image-warn.png'
import MobileWarnSvg from 'images/image-warn.svg'
import {useTranslation} from 'react-i18next'
import {BiRightArrowAlt} from 'react-icons/bi'
import {getCampaignListingUrl} from 'constants/config'

// that was rerouted from the old redeem v1 (which was NEA vouchers).
const NeaExpiredVoucherError = () => {
  const {t} = useTranslation('common')
  const campaignListingUrl = getCampaignListingUrl()

  return (
    <Box
      height='100%'
      padding='32px 24px'
      color='white'
      backgroundColor='white'
    >
      <HStack
        alignItems='center'
        align='stretch'
        justify='space-between'
        backgroundColor='white'
      >
        <Image
          height='40px'
          alt='Campaign Logo'
          fallbackSrc={OldCFHPLogo}
          src={OldCFHPLogo}
        />
        <LanguageSelector color='light' />
      </HStack>
      <Image
        alignSelf='flex-start'
        width='72px'
        marginTop='40px'
        alt='Custom header image'
        fallbackSrc={MobileWarnPng}
        src={MobileWarnSvg}
      />
      <VStack align='stretch' marginTop='24px' spacing='16px'>
        <Text textStyle='h4' color='neutral.900'>
          {t('campaignEnded.title', {
            campaignName: 'Climate Friendly Households Programme Vouchers',
          })}
        </Text>
        <Text textStyle='body1' color='neutral.700'>
          {t('campaignEnded.info', {
            expiryDate: '20 March 2024',
          })}
        </Text>
      </VStack>
      <Button
        as='a'
        height='auto'
        marginTop='48px'
        whiteSpace='normal'
        blockSize='auto'
        href={campaignListingUrl}
        isFullWidth
        rightIcon={<BiRightArrowAlt />}
      >
        {t('campaignEnded.goCampaignListingPage')}
      </Button>
    </Box>
  )
}

export default NeaExpiredVoucherError
